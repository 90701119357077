/* eslint-disable no-undef */
import React from "react"
import TemplatePageTwiliovsBeex from "@components/pageFunctionalities/pageTwiliovsBeex/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Beex: Alternativa #1 a Twilio para crear chatbots en WhatsApp [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/whatsapp-business-api/chatbots-twilio-beex/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="¿Aún no te convence Twilio para crear chatbots en WhatsApp? Prueba Beex sin costo alguno y nota la verdadera diferencia."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Beex: Alternativa #1 a Twilio para crear chatbots en WhatsApp"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/whatsapp-business-api/chatbots-twilio-beex/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="¿Aún no te convence Twilio para crear chatbots en WhatsApp? Prueba Beex sin costo alguno y nota la verdadera diferencia."
      />
    </Helmet>
    <TemplatePageTwiliovsBeex location={location} />
  </div>
)

export default IndexPage
